import {UPDATE_CUSTOMER} from "../actionTypes";

const initialState = {
    customer: '000000000',
};

function rootReducer(state = initialState, action) {
    switch (action.type) {
        case UPDATE_CUSTOMER: {
            const {customer} = action.payload;
            return Object.assign({}, state, {customer: customer,})
        }
        default:
            return state;
    }
}

export default rootReducer;

