import {UPDATE_CUSTOMER, UPDATE_CONNECTION_STATUS} from "./actionTypes";

export function updateCustomer(payload) {
    return {
        type: UPDATE_CUSTOMER,
        payload,
    }
}

export function updateConnectionStatus(payload) {
    return {
        type: UPDATE_CONNECTION_STATUS,
        payload,
    }
}