import React, {Component} from 'react';
import './App.css';
import QrScanner from './components/QrScanner';
import CustomerRecord from './components/CustomerRecord';
import SyncRecord from "./Pouchdb";
import {Offline} from 'react-detect-offline';


const polling = {
    enabled: true,
    interval: 2000,
    timeout: 500,
    url: 'https://couchdb.agfc.com/licenses/'
};

class App extends Component {
    render() {
        return (
            <div className="App">
                <Offline polling={polling}>You are offline, results may be limited.</Offline>
                <header className="App-header">
                    <SyncRecord/>
                    <QrScanner/>
                </header>
                <div className="App-body">
                    <CustomerRecord/>
                </div>
            </div>
        );
    }
}

export default App
