import React from 'react';
import {connect} from 'react-redux';
import * as Sentry from "@sentry/browser";
import {db, remoteDatabase} from '../Pouchdb';
/*
<Detector
    render={({online}) => (
        <div className={online ? "normal" : "warning"}>
            You are currently {online ? "online" : "offline"}
        </div>
    )}
/>
*/

const mapStateToProps = state => {
    return {
        customer: state.customer
    }
};

class CustomerRecord extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            license: {},
            privileges: {},
            key: '',
        };
    }

    componentDidUpdate(prevProps) {
        const t = this;
        let onLine = navigator.onLine;
        let customer = this.props.customer;
        if (customer !== '000000000') {
            if (customer !== prevProps.customer) {
                if (onLine) {
                    console.log('remote');
                    remoteDatabase.get(customer, function (error, doc) {
                        if (error) {
                            Sentry.captureException(error);
                            alert('No record found for this CID.');
                        }
                        if (doc) {
                            t.setState({
                                license: doc,
                                privileges: doc.privileges,
                                key: doc.id,
                                isLoading: true
                            })
                        }
                    })
                } else {
                    db.get(customer, function (error, doc) {
                        if (error) {
                            Sentry.captureException(error);
                            alert(
                                'No record found for this CID.\n' +
                                'You are offline, and may not have most up-to-date records.'
                            );
                        }
                        if (doc) {
                            t.setState({
                                license: doc,
                                privileges: doc.privileges,
                                key: doc.id,
                                isLoading: true
                            })
                        }
                    });
                }
            }
        } else if (customer === '000000000') {
            if (this.props.customer !== prevProps.customer) {
                this.setState({
                    license: {},
                    privileges: {},
                    key: '',
                })
            }
        }
    }

    render() {
        let license = this.state.license;
        let privileges = this.state.privileges;

        return (
            <div>
                <p>Customer ID: {license.customerid}</p>
                <p>Name:{license.firstname} {license.lastname}</p>
                <p>Privileges:</p>
                <ul>
                    {
                        Object.keys(privileges).map((key, index) => (
                            <li key={index}>
                                {privileges[key].privilege}
                                <br/>&nbsp;&nbsp;&nbsp;&nbsp;Starts: {privileges[key].effectivedate}
                                <br/>&nbsp;&nbsp;&nbsp;&nbsp;Expires: {privileges[key].expirationdate}
                            </li>
                        ))
                    }
                </ul>
            </div>
        )
    }
}

export default connect(mapStateToProps)(CustomerRecord);
