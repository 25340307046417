import React, {Component} from 'react';
import {connect} from 'react-redux';
import {updateCustomer} from "../redux/actions";
import QrReader from 'react-qr-reader';
import * as Sentry from "@sentry/browser";

function mapDispatchToProps(dispatch) {
    return {
        updateCustomer: customer => dispatch(updateCustomer(customer))
    }
}

function pad(num, size) {
    let s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
}


class QrScanner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            result: '000000000',
        };
        this.handleChange.bind(this);
    }

    handleScan = data => {
        if (data) {
            if (data.length < 9) {
                data = pad(data, 9);
            }
            this.setState({
                result: data
            });
            this.props.updateCustomer({customer: data});

        }

    };

    handleError = err => {
        Sentry.captureException(err);
    };

    handleChange = event => {
        if (event.key === "Enter") {
            this.handleScan(event.target.value);
        }

    };

    handleClear = () => {
        this.setState({
            result: '000000000'
        });
        document.getElementById('cid_input').value = '';
        this.props.updateCustomer({customer: '000000000'});
    };

    render() {
        return (
            <div className="Scanner">
                <QrReader
                    delay={300}
                    onError={this.handleError}
                    onScan={this.handleScan}
                    style={{width: '100%'}}
                />
                <p>CID: {this.state.result}</p>
                <button onClick={this.handleClear}>Clear or Start New Scan</button>
                <fieldset>
                    <legend>Enter CID Here:</legend>
                    <input
                        id="cid_input"
                        onKeyPress={this.handleChange}
                        type="number"
                    />
                </fieldset>
            </div>
        )
    }
}

export default connect(null, mapDispatchToProps)(QrScanner);