import React from 'react';

import * as Sentry from "@sentry/browser";
import {connect} from "react-redux";
import PouchDB from "pouchdb-browser";

export const db = new PouchDB("licenses", {size: 10000, batch_size: 1000});
export const remoteDatabase = new PouchDB("https://couchdb.agfc.com/licenses");

const mapStateToProps = state => {
    return {localCount: state.localCount}
};

let pendingMax = 0;
let batch_size = 1000;    // must match your replication options

function getProgress(pending) {
    let progress;
    pendingMax = pendingMax < pending ? pending + batch_size : pendingMax;  // first time capture
    if (pendingMax > 0) {
        progress = 1 - pending / pendingMax;
        if (pending === 0) {
            pendingMax = 0;    // reset for live/next replication
        }
    } else {
        progress = 1;  // 100%
    }
    progress = progress * 100;
    return progress.toFixed(2);
}

class SyncRecord extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            localCount: "Loading...",
        };
    }

    startReplication() {
        let s = this;
        PouchDB.replicate(remoteDatabase, db, {
            live: true,
            heartbeat: false,
            timeout: false,
            retry: true,
            auto_compaction: false,
            batch_size: 1000
        }).on('change', function (info) {
            s.setState({localCount: getProgress(info.pending) + '%'});
        }).on('paused', function (info) {
            // console.log('paused');
            let progress = getProgress(info.pending);
            s.setState({localCount: progress + '%'});
        }).on('active', function () {
            console.log('active')
        }).on('denied', function () {
            console.log('denied')
        }).on('complete', function () {
            console.log('complete')
        }).on('error', function (err) {
            console.log('error')
            Sentry.captureException(err);
        });
    }

    componentDidMount() {
        this.startReplication();
    }


    render() {
        return (
            <div>
                <p>Version 1.1.1</p>
                <p>Offline Sync Completion: {this.state.localCount}</p>
            </div>
        )
    }
}


export default connect(mapStateToProps)(SyncRecord);